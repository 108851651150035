// What used to be 'lib.js'
import 'jquery';

window.$ = jQuery;

import 'jquery.hotkeys';
import 'bootstrap';
import '@/external-deps/bootstrap/js/bootstrap-wysiwyg.js';

// Used to reside in index.html
import './jquery-setup';